import React from 'react';
import ApartmentTherapy from './assets/apartment-therapy.svg';
import Bazaar from './assets/bazaar.svg';
import NyTimes from './assets/ny-times.svg';
import Vogue from './assets/vogue.svg';
import PeopleMagazine from './assets/people_magazine.svg';

const logos = [
  <Vogue key="vogue" />,
  <NyTimes key="nytimes" />,
  <Bazaar key="bazaar" />,
  <ApartmentTherapy key="apartment" />,
  <PeopleMagazine key="peoplemagazine" />,
];

const LogoWithQuote = [
  {
    key: 'vogue',
    Logo: Vogue,
    quote: `“Imagine if you could hire someone to scour your Pinterest boards and
        translate your pins into a perfectly decorated room in your home.”`
  },
  {
    key: 'nytimes',
    Logo: NyTimes,
    quote: `“Havenly — offering services that were affordable for everyone.”`
  },
  {
    key: 'bazaar',
    Logo: Bazaar,
    quote: `“If you’ve always dreamed of a glamorous home makeover experience but haven’t had the interior designer budget to go along with it, then you’re in luck.”`
  },
  {
    key: 'peoplemagazine',
    Logo: PeopleMagazine,
    quote: `“Surprisingly easy and enjoyable, and a process I am looking forward to repeating!”`
  },
  {
    key: 'apartment',
    Logo: ApartmentTherapy,
    quote: `“Unlike [another design service] though, we actually loved everything we saw from Havenly.”`
  },
];

export { LogoWithQuote };

export default logos;
